.advanced_filterModal {
    margin-top: 30px;
    &__dimensionBlock__conditions{
        display: flex;
        align-items: center
    }
   &__error-message{
    color: red;
    font-size: 12px;
    margin-left: 0.5rem;
    display: block;
   }
}
